
import { defineComponent } from 'vue'
import {
  Pokemon,
  PokemonNature,
  pokemonList,
  pokemonNatures,
  StatusCalculator,
} from '@/components/PokemonModule'

import DropDown from '@/components/DropDown.vue'

const statusParameters = ['H', 'A', 'B', 'C', 'D', 'S']

type StatusParameter = {
  ev: number
  rv: number
}

type PokemonStatus = {
  H: StatusParameter
  A: StatusParameter
  B: StatusParameter
  C: StatusParameter
  D: StatusParameter
  S: StatusParameter
  [key: string]: StatusParameter
}

type StatusTable = {
  iv: number
  H: number
  A: number
  B: number
  C: number
  D: number
  S: number
  [key: string]: number
}

type NumberArrays = {
  H: Array<number>
  A: Array<number>
  B: Array<number>
  C: Array<number>
  D: Array<number>
  S: Array<number>
  [key: string]: Array<number>
}

type NumberSets = {
  H: Set<number>
  A: Set<number>
  B: Set<number>
  C: Set<number>
  D: Set<number>
  S: Set<number>
  [key: string]: Set<number>
}

type NumberSetArrays = {
  H: Array<Set<number>>
  A: Array<Set<number>>
  B: Array<Set<number>>
  C: Array<Set<number>>
  D: Array<Set<number>>
  S: Array<Set<number>>
  [key: string]: Array<Set<number>>
}

type DataType = {
  pokemonList: Array<Pokemon>
  selectedPokemon: Pokemon | null
  selectedNature: PokemonNature
  level: number
  status: PokemonStatus
  natureList: Array<PokemonNature>
  ivs: Array<number>
  statusTables: Array<Array<StatusTable>>
  candidates: NumberSetArrays
  resultIVs: NumberArrays
  onCalcuration: boolean
}

export default defineComponent({
  components: {
    DropDown,
  },
  data(): DataType {
    return {
      pokemonList: pokemonList,
      selectedPokemon: null,
      selectedNature: pokemonNatures[6],
      level: 50,
      status: {
        H: {
          ev: 0,
          rv: 0,
        },
        A: {
          ev: 0,
          rv: 0,
        },
        B: {
          ev: 0,
          rv: 0,
        },
        C: {
          ev: 0,
          rv: 0,
        },
        D: {
          ev: 0,
          rv: 0,
        },
        S: {
          ev: 0,
          rv: 0,
        },
      },
      natureList: pokemonNatures,
      ivs: Array.from({ length: 32 }, (_, i) => 31 - i),
      statusTables: [],
      candidates: {
        H: [],
        A: [],
        B: [],
        C: [],
        D: [],
        S: [],
      },
      resultIVs: {
        H: [],
        A: [],
        B: [],
        C: [],
        D: [],
        S: [],
      },
      onCalcuration: false,
    }
  },
  methods: {
    calculateStatusTable(): void {
      if (!this.selectedPokemon) {
        return
      }

      const statusTable: Array<StatusTable> = []
      const result: NumberSets = {
        H: new Set<number>(),
        A: new Set<number>(),
        B: new Set<number>(),
        C: new Set<number>(),
        D: new Set<number>(),
        S: new Set<number>(),
      }

      const calculator = new StatusCalculator(
        this.selectedPokemon,
        this.level,
        this.selectedNature
      )
      for (let i = 31; i >= 0; i--) {
        const stat: StatusTable = {
          iv: i,
          H: calculator.calculateH(i, this.status.H.ev),
          A: calculator.calculateA(i, this.status.A.ev),
          B: calculator.calculateB(i, this.status.B.ev),
          C: calculator.calculateC(i, this.status.C.ev),
          D: calculator.calculateD(i, this.status.D.ev),
          S: calculator.calculateS(i, this.status.S.ev),
        }
        statusTable.push(stat)
        for (const p of statusParameters) {
          if (this.status[p].rv === stat[p]) {
            result[p].add(i)
          }
        }
      }
      for (const p of statusParameters) {
        this.candidates[p].push(result[p])
        this.resultIVs[p] = Array.from(result[p])
      }

      this.statusTables = [statusTable]
      this.onCalcuration = true
    },
    narrowDownStatus(): void {
      if (!this.selectedPokemon) {
        return
      }

      const statusTable: Array<StatusTable> = []
      const result: NumberSets = {
        H: new Set<number>(),
        A: new Set<number>(),
        B: new Set<number>(),
        C: new Set<number>(),
        D: new Set<number>(),
        S: new Set<number>(),
      }
      const calculator = new StatusCalculator(
        this.selectedPokemon,
        this.level,
        this.selectedNature
      )
      for (let i = 31; i >= 0; i--) {
        const stat: StatusTable = {
          iv: i,
          H: calculator.calculateH(i, this.status.H.ev),
          A: calculator.calculateA(i, this.status.A.ev),
          B: calculator.calculateB(i, this.status.B.ev),
          C: calculator.calculateC(i, this.status.C.ev),
          D: calculator.calculateD(i, this.status.D.ev),
          S: calculator.calculateS(i, this.status.S.ev),
        }
        statusTable.push(stat)
        for (const p of statusParameters) {
          if (this.status[p].rv === stat[p]) {
            result[p].add(i)
          }
        }
      }
      for (const p of statusParameters) {
        this.candidates[p].push(result[p])
      }

      this.statusTables.push(statusTable)

      for (const p of statusParameters) {
        if (this.candidates[p].length > 1) {
          const flattened = this.candidates[p].map(x => Array.from(x)).flat()
          const candidates = [...new Set(flattened)]
          const res = candidates.filter(v =>
            this.candidates[p].every(rc => rc.has(v))
          )
          this.resultIVs[p] = res
        }
      }
    },
    clearResult(): void {
      this.level = 50
      this.selectedNature = pokemonNatures[6]
      this.candidates = {
        H: [],
        A: [],
        B: [],
        C: [],
        D: [],
        S: [],
      }
      this.resultIVs = {
        H: [],
        A: [],
        B: [],
        C: [],
        D: [],
        S: [],
      }
      this.statusTables = []
      const status = {
        H: {
          ev: 0,
          rv: this.status.H.rv,
        },
        A: {
          ev: 0,
          rv: this.status.A.rv,
        },
        B: {
          ev: 0,
          rv: this.status.B.rv,
        },
        C: {
          ev: 0,
          rv: this.status.C.rv,
        },
        D: {
          ev: 0,
          rv: this.status.D.rv,
        },
        S: {
          ev: 0,
          rv: this.status.S.rv,
        },
      }
      this.status = status
      this.onCalcuration = false
    },
    onEffortValueChange(target: number, current: number, next: number): number {
      const max = 508
      const after = this.evTotal + next - current
      if (after > max) {
        const over = after - max
        next -= over
      }
      const nextStatus = {
        ev: next,
        rv: this.status[target].rv,
      }
      this.status[target] = nextStatus
      return next
    },
    getTableRowStyle(v: string): string {
      if (v === this.selectedNature.plus) {
        return 'background-red'
      }
      if (v === this.selectedNature.minus) {
        return 'background-blue'
      }
      return 'background-default'
    },
    getTableCellStyle(value: number, parameter: string, iv: number): string {
      return this.resultIVs[parameter].includes(iv)
        ? 'background-red'
        : 'background-default'
    },
    setStatusValue(): void {
      if (!this.selectedPokemon) {
        return
      }
      const extractIV = (p: string): number =>
        this.resultIVs[p].length === 0 ? 31 : this.resultIVs[p][0]
      const calculator = new StatusCalculator(
        this.selectedPokemon,
        this.level,
        this.selectedNature
      )

      const newStatus: PokemonStatus = {
        H: {
          ev: this.status.H.ev,
          rv: calculator.calculateH(extractIV('H'), this.status.H.ev),
        },
        A: {
          ev: this.status.A.ev,
          rv: calculator.calculateA(extractIV('A'), this.status.A.ev),
        },
        B: {
          ev: this.status.B.ev,
          rv: calculator.calculateB(extractIV('B'), this.status.B.ev),
        },
        C: {
          ev: this.status.C.ev,
          rv: calculator.calculateC(extractIV('C'), this.status.C.ev),
        },
        D: {
          ev: this.status.D.ev,
          rv: calculator.calculateD(extractIV('D'), this.status.D.ev),
        },
        S: {
          ev: this.status.S.ev,
          rv: calculator.calculateS(extractIV('S'), this.status.S.ev),
        },
      }
      this.status = Object.assign({}, this.status, newStatus)
    },
  },
  computed: {
    evTotal(): number {
      return (
        this.status.H.ev +
        this.status.A.ev +
        this.status.B.ev +
        this.status.C.ev +
        this.status.D.ev +
        this.status.S.ev
      )
    },
    individualValues(): string {
      const arrayToString = (arr: Array<number>): string => {
        if (!arr?.length) {
          return '?'
        } else if (arr.length === 1) {
          return String(arr[0])
        } else {
          return `[${arr.slice(-1)[0]}-${arr[0]}]`
        }
      }

      const h = arrayToString(this.resultIVs.H)
      const a = arrayToString(this.resultIVs.A)
      const b = arrayToString(this.resultIVs.B)
      const c = arrayToString(this.resultIVs.C)
      const d = arrayToString(this.resultIVs.D)
      const s = arrayToString(this.resultIVs.S)

      return `${h}-${a}-${b}-${c}-${d}-${s}`
    },
  },
  watch: {
    selectedPokemon(): void {
      this.clearResult()
      this.setStatusValue()
    },
    selectedNature(): void {
      this.setStatusValue()
    },
    level(): void {
      this.setStatusValue()
    },
  },
})
