import { createApp } from 'vue'
import VueGtag from 'vue-gtag-next'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

createApp(App)
  .use(router)
  .use(VueGtag, {
    property: {
      id: 'UA-168724597-7',
    },
  })
  .mount('#app')
