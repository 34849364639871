
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    getLinkStyle(links: Array<string>): string {
      return links.includes(this.$route.path)
        ? 'header-link-current'
        : 'header-link'
    },
  },
})
