
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fullName: {
      type: String,
      default: '',
    },
    nature: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: null,
    },
    h: {
      type: Number,
      default: null,
    },
    a: {
      type: Number,
      default: null,
    },
    b: {
      type: Number,
      default: null,
    },
    c: {
      type: Number,
      default: null,
    },
    d: {
      type: Number,
      default: null,
    },
    s: {
      type: Number,
      default: null,
    },
    baseStatH: {
      type: Number,
      default: null,
    },
    baseStatA: {
      type: Number,
      default: null,
    },
    baseStatB: {
      type: Number,
      default: null,
    },
    baseStatC: {
      type: Number,
      default: null,
    },
    baseStatD: {
      type: Number,
      default: null,
    },
    baseStatS: {
      type: Number,
      default: null,
    },
    evH: {
      type: Number,
      default: 0,
    },
    evA: {
      type: Number,
      default: 0,
    },
    evB: {
      type: Number,
      default: 0,
    },
    evC: {
      type: Number,
      default: 0,
    },
    evD: {
      type: Number,
      default: 0,
    },
    evS: {
      type: Number,
      default: 0,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseStats(): string {
      return `${this.baseStatH}-${this.baseStatA}-${this.baseStatB}-${this.baseStatC}-${this.baseStatD}-${this.baseStatS}`
    },
    ev(): string {
      return `${this.evH}-${this.evA}-${this.evB}-${this.evC}-${this.evD}-${this.evS}`
    },
    eiB(): number {
      return this.h * this.b
    },
    eiD(): number {
      return this.h * this.d
    },
  },
  methods: {
    clickPin(): void {
      this.$emit('item-pinned')
    },
    clickDelete(): void {
      this.$emit('item-deleted')
    },
    clickPromote(): void {
      this.$emit('item-promoted')
    },
    showsPinButton(): boolean {
      return this.isPinned === false
    },
    showsDeleteButton(): boolean {
      return this.isPinned === true
    },
  },
})
