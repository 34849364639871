
import { defineComponent, nextTick } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import DropDown from '../components/DropDown.vue'
import StatusCard from '../components/StatusCard.vue'
import {
  pokemonList,
  PokemonNature,
  Pokemon,
  pokemonNatures,
  StatusCalculator,
} from '../components/PokemonModule'

type StatusParameter = {
  ev: number
  iv: number
}

type PokemonStatus = {
  H: StatusParameter
  A: StatusParameter
  B: StatusParameter
  C: StatusParameter
  D: StatusParameter
  S: StatusParameter
  [key: string]: StatusParameter
}

type DataType = {
  pokemonList: Array<Pokemon>
  selectedPokemon: Pokemon | null
  selectedNature: PokemonNature
  level: number
  status: PokemonStatus
  natureList: Array<PokemonNature>
  pinnedItems: Array<DisplayPokemon>
}

class DisplayPokemon {
  private _calculator: StatusCalculator

  constructor(
    private _pokemon: Pokemon,
    private _status: PokemonStatus,
    private _level: number,
    private _nature: PokemonNature
  ) {
    this._calculator = new StatusCalculator(_pokemon, _level, _nature)
  }

  get pokemon(): Pokemon {
    return this._pokemon
  }

  get status(): PokemonStatus {
    return this._status
  }

  get nature(): PokemonNature {
    return this._nature
  }

  get fullName(): string {
    return this._pokemon.fullName
  }

  get natureName(): string {
    return this._nature.name
  }

  get level(): number {
    return this._level
  }

  get baseStatH(): number {
    return this._pokemon.baseStats.H
  }

  get baseStatA(): number {
    return this._pokemon.baseStats.A
  }

  get baseStatB(): number {
    return this._pokemon.baseStats.B
  }

  get baseStatC(): number {
    return this._pokemon.baseStats.C
  }

  get baseStatD(): number {
    return this._pokemon.baseStats.D
  }

  get baseStatS(): number {
    return this._pokemon.baseStats.S
  }

  get h(): number {
    const st = this._status.H
    return this._calculator.calculateH(st.iv, st.ev)
  }

  get a(): number {
    const st = this._status.A
    return this._calculator.calculateA(st.iv, st.ev)
  }

  get b(): number {
    const st = this._status.B
    return this._calculator.calculateB(st.iv, st.ev)
  }

  get c(): number {
    const st = this._status.C
    return this._calculator.calculateC(st.iv, st.ev)
  }

  get d(): number {
    const st = this._status.D
    return this._calculator.calculateD(st.iv, st.ev)
  }

  get s(): number {
    const st = this._status.S
    return this._calculator.calculateS(st.iv, st.ev)
  }

  get evH(): number {
    return this._status.H.ev
  }

  get evA(): number {
    return this._status.A.ev
  }

  get evB(): number {
    return this._status.B.ev
  }

  get evC(): number {
    return this._status.C.ev
  }

  get evD(): number {
    return this._status.D.ev
  }

  get evS(): number {
    return this._status.S.ev
  }
}

export default defineComponent({
  components: {
    DropDown,
    StatusCard,
  },
  data(): DataType {
    return {
      pokemonList: pokemonList,
      selectedPokemon: null,
      selectedNature: pokemonNatures[6],
      level: 50,
      status: {
        H: {
          ev: 0,
          iv: 31,
        },
        A: {
          ev: 0,
          iv: 31,
        },
        B: {
          ev: 0,
          iv: 31,
        },
        C: {
          ev: 0,
          iv: 31,
        },
        D: {
          ev: 0,
          iv: 31,
        },
        S: {
          ev: 0,
          iv: 31,
        },
      },
      natureList: pokemonNatures,
      pinnedItems: [],
    }
  },
  methods: {
    onEffortValueChange: function (
      target: number,
      current: number,
      next: number
    ): number {
      const max = 508
      const after = this.evTotal + next - current
      if (after > max) {
        const over = after - max
        next -= over
      }

      const newStatus: StatusParameter = {
        ev: next,
        iv: this.status[target].iv,
      }
      this.status[target] = newStatus
      return next
    },
    getTableStyle: function (v: string): string {
      if (v === this.selectedNature.plus) {
        return 'background-red'
      }
      if (v === this.selectedNature.minus) {
        return 'background-blue'
      }
      return 'background-default'
    },
    pinItem: function (): void {
      if (this.currentPokemon === null) {
        return
      }

      this.pinnedItems.push(this.currentPokemon)
    },
    deleteItem: function (num: number): void {
      if (num > this.pinnedItems.length) {
        return
      }

      this.pinnedItems.splice(num, 1)
    },
    promoteItem: function (num: number): void {
      if (num > this.pinnedItems.length) {
        return
      }

      const target: DisplayPokemon = this.pinnedItems[num]
      this.selectedPokemon = target.pokemon
      nextTick(() => {
        this.selectedNature = target.nature
        this.status = cloneDeep(target.status)
        this.level = target.level
      })
    },
    resetValues: function (): void {
      this.level = 50
      this.selectedNature = pokemonNatures[6]
      this.status = {
        H: {
          ev: 0,
          iv: 31,
        },
        A: {
          ev: 0,
          iv: 31,
        },
        B: {
          ev: 0,
          iv: 31,
        },
        C: {
          ev: 0,
          iv: 31,
        },
        D: {
          ev: 0,
          iv: 31,
        },
        S: {
          ev: 0,
          iv: 31,
        },
      }
    },
    updateSelectedValue: function (val: Pokemon): void {
      this.selectedPokemon = val
      this.resetValues()
    },
    getCurrentPokemon(): DisplayPokemon | null {
      if (this.selectedPokemon === null) {
        return null
      }

      return new DisplayPokemon(
        this.selectedPokemon,
        cloneDeep(this.status),
        this.level,
        this.selectedNature
      )
    },
  },
  computed: {
    currentPokemon(): DisplayPokemon | null {
      if (this.selectedPokemon === null) {
        return null
      }

      return new DisplayPokemon(
        this.selectedPokemon,
        cloneDeep(this.status),
        this.level,
        this.selectedNature
      )
    },
    evTotal(): number {
      return (
        this.status.H.ev +
        this.status.A.ev +
        this.status.B.ev +
        this.status.C.ev +
        this.status.D.ev +
        this.status.S.ev
      )
    },
  },
  watch: {
    selectedPokemon: {
      handler: function () {
        this.resetValues()
      },
      deep: true,
    },
  },
})
