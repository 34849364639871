import Pokedex from 'pokedex.js'

const pokedex = new Pokedex('ja')
const pokemons = JSON.parse(pokedex.sort('NationalNumber').get())

export class BaseStats {
  H: number
  A: number
  B: number
  C: number
  D: number
  S: number;
  [key: string]: number

  // eslint-disable-next-line
  constructor(val: any) {
    this.H = Number(val.H)
    this.A = Number(val.A)
    this.B = Number(val.B)
    this.C = Number(val.C)
    this.D = Number(val.D)
    this.S = Number(val.S)
  }
}

export class PokemonNature {
  name: string
  plus: string
  minus: string
  constructor(name: string, plus: string, minus: string) {
    this.name = name
    this.plus = plus
    this.minus = minus
  }

  calculateNatureBonus(v: string): number {
    if (v === this.plus) {
      return 1.1
    }
    if (v === this.minus) {
      return 0.9
    }
    return 1.0
  }
}

export class Pokemon {
  id: string
  name: string
  formName?: string
  baseStats: BaseStats

  // eslint-disable-next-line
  constructor(o: any) {
    this.id = o.id
    this.name = o.name
    this.formName = o.formName
    this.baseStats = new BaseStats(o.baseStats)
  }

  get fullName(): string {
    return this.formName ? `${this.name} (${this.formName})` : this.name
  }
}

export class StatusCalculator {
  constructor(
    private pokemon: Pokemon,
    private level: number,
    private nature: PokemonNature
  ) {}

  calculateH(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.H
    if (baseStat === 1) {
      return 1
    }

    return (
      Math.floor(((baseStat * 2 + iv + ev / 4) * this.level) / 100) +
      10 +
      this.level
    )
  }

  calculateA(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.A
    const bonus = this.nature.calculateNatureBonus('A')
    return this.calculate(baseStat, iv, ev, bonus)
  }

  calculateB(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.B
    const bonus = this.nature.calculateNatureBonus('B')
    return this.calculate(baseStat, iv, ev, bonus)
  }

  calculateC(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.C
    const bonus = this.nature.calculateNatureBonus('C')
    return this.calculate(baseStat, iv, ev, bonus)
  }

  calculateD(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.D
    const bonus = this.nature.calculateNatureBonus('D')
    return this.calculate(baseStat, iv, ev, bonus)
  }

  calculateS(iv: number, ev: number): number {
    const baseStat = this.pokemon.baseStats.S
    const bonus = this.nature.calculateNatureBonus('S')
    return this.calculate(baseStat, iv, ev, bonus)
  }

  private calculate(bs: number, iv: number, ev: number, bonus: number): number {
    return Math.floor(
      (Math.floor(((bs * 2 + iv + ev / 4) * this.level) / 100) + 5) * bonus
    )
  }
}

export const pokemonList = Array.from(pokemons).map(p => new Pokemon(p))

export const pokemonNatures = [
  new PokemonNature('いじっぱり', 'A', 'C'),
  new PokemonNature('うっかりや', 'C', 'D'),
  new PokemonNature('おくびょう', 'S', 'A'),
  new PokemonNature('おだやか', 'D', 'A'),
  new PokemonNature('おっとり', 'C', 'B'),
  new PokemonNature('おとなしい', 'D', 'B'),
  new PokemonNature('がんばりや', '-', '-'),
  new PokemonNature('きまぐれ', '-', '-'),
  new PokemonNature('さみしがり', 'A', 'B'),
  new PokemonNature('しんちょう', 'D', 'C'),
  new PokemonNature('すなお', '-', '-'),
  new PokemonNature('ずぶとい', 'B', 'A'),
  new PokemonNature('せっかち', 'S', 'B'),
  new PokemonNature('てれや', '-', '-'),
  new PokemonNature('のうてんき', 'B', 'D'),
  new PokemonNature('のんき', 'B', 'S'),
  new PokemonNature('ひかえめ', 'C', 'A'),
  new PokemonNature('なまいき', 'D', 'S'),
  new PokemonNature('まじめ', '-', '-'),
  new PokemonNature('むじゃき', 'S', 'D'),
  new PokemonNature('やんちゃ', 'A', 'D'),
  new PokemonNature('れいせい', 'C', 'S'),
  new PokemonNature('ゆうかん', 'A', 'S'),
  new PokemonNature('ようき', 'S', 'C'),
  new PokemonNature('わんぱく', 'B', 'C'),
]
