<template>
  <div>
    <div>
      <h3>ポケモンステータス計算機</h3>
      <DropDown
        :modelValue="selectedPokemon"
        @update:modelValue="updateSelectedValue($event)"
        :pokemonList="pokemonList"
      />
    </div>

    <span v-if="selectedPokemon" class="bold newline">{{
      selectedPokemon.fullName
    }}</span>
    <div v-if="selectedPokemon" ref="parameter-input">
      <label for="level_input">レベル:</label>
      <input
        id="level_input"
        type="number"
        v-model.number="level"
        min="1"
        max="100"
      />
      <label for="nature_input" class="left-space">性格:</label>
      <select id="nature_input" v-model="selectedNature">
        <option
          v-for="nature in natureList"
          v-bind:key="nature.name"
          v-bind:value="nature"
        >
          {{ nature.name }}
        </option>
      </select>

      <table align="center">
        <thead>
          <tr>
            <th colspan="3" align="center">努力値</th>
            <th>個体値</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(value, name, index) in status"
            :key="index"
            v-bind:class="getTableStyle(name)"
          >
            <td width="8%">
              {{ name }}
            </td>
            <td width="12%">
              <input
                type="number"
                min="0"
                max="252"
                step="4"
                :value="value.ev"
                @change="
                  onEffortValueChange(
                    name,
                    value.ev,
                    Number($event.target.value)
                  )
                "
              />
            </td>
            <td width="60%">
              <input
                type="range"
                min="0"
                max="252"
                step="4"
                :value="value.ev"
                @change="
                  onEffortValueChange(
                    name,
                    value.ev,
                    Number($event.target.value)
                  )
                "
                class="range-slider"
              />
            </td>
            <td width="20%">
              <input type="number" v-model.number="value.iv" min="0" max="31" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <StatusCard
      v-if="selectedPokemon"
      :fullName="currentPokemon.fullName"
      :nature="currentPokemon.natureName"
      :level="currentPokemon.level"
      :baseStatH="currentPokemon.baseStatH"
      :baseStatA="currentPokemon.baseStatA"
      :baseStatB="currentPokemon.baseStatB"
      :baseStatC="currentPokemon.baseStatC"
      :baseStatD="currentPokemon.baseStatD"
      :baseStatS="currentPokemon.baseStatS"
      :h="currentPokemon.h"
      :a="currentPokemon.a"
      :b="currentPokemon.b"
      :c="currentPokemon.c"
      :d="currentPokemon.d"
      :s="currentPokemon.s"
      :evH="currentPokemon.evH"
      :evA="currentPokemon.evA"
      :evB="currentPokemon.evB"
      :evC="currentPokemon.evC"
      :evD="currentPokemon.evD"
      :evS="currentPokemon.evS"
      @item-pinned="pinItem"
    />

    <div class="pinned-item" v-if="pinnedItems.length > 0">
      <p class="saved-items">保存したステータス</p>
      <div v-for="(item, index) in pinnedItems" :key="item.fullName + index">
        <StatusCard
          :fullName="item.fullName"
          :nature="item.natureName"
          :level="item.level"
          :baseStatH="item.baseStatH"
          :baseStatA="item.baseStatA"
          :baseStatB="item.baseStatB"
          :baseStatC="item.baseStatC"
          :baseStatD="item.baseStatD"
          :baseStatS="item.baseStatS"
          :h="item.h"
          :a="item.a"
          :b="item.b"
          :c="item.c"
          :d="item.d"
          :s="item.s"
          :evH="item.evH"
          :evA="item.evA"
          :evB="item.evB"
          :evC="item.evC"
          :evD="item.evD"
          :evS="item.evS"
          :isPinned="true"
          @item-deleted="deleteItem(index)"
          @item-promoted="promoteItem(index)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import DropDown from '../components/DropDown.vue'
import StatusCard from '../components/StatusCard.vue'
import {
  pokemonList,
  PokemonNature,
  Pokemon,
  pokemonNatures,
  StatusCalculator,
} from '../components/PokemonModule'

type StatusParameter = {
  ev: number
  iv: number
}

type PokemonStatus = {
  H: StatusParameter
  A: StatusParameter
  B: StatusParameter
  C: StatusParameter
  D: StatusParameter
  S: StatusParameter
  [key: string]: StatusParameter
}

type DataType = {
  pokemonList: Array<Pokemon>
  selectedPokemon: Pokemon | null
  selectedNature: PokemonNature
  level: number
  status: PokemonStatus
  natureList: Array<PokemonNature>
  pinnedItems: Array<DisplayPokemon>
}

class DisplayPokemon {
  private _calculator: StatusCalculator

  constructor(
    private _pokemon: Pokemon,
    private _status: PokemonStatus,
    private _level: number,
    private _nature: PokemonNature
  ) {
    this._calculator = new StatusCalculator(_pokemon, _level, _nature)
  }

  get pokemon(): Pokemon {
    return this._pokemon
  }

  get status(): PokemonStatus {
    return this._status
  }

  get nature(): PokemonNature {
    return this._nature
  }

  get fullName(): string {
    return this._pokemon.fullName
  }

  get natureName(): string {
    return this._nature.name
  }

  get level(): number {
    return this._level
  }

  get baseStatH(): number {
    return this._pokemon.baseStats.H
  }

  get baseStatA(): number {
    return this._pokemon.baseStats.A
  }

  get baseStatB(): number {
    return this._pokemon.baseStats.B
  }

  get baseStatC(): number {
    return this._pokemon.baseStats.C
  }

  get baseStatD(): number {
    return this._pokemon.baseStats.D
  }

  get baseStatS(): number {
    return this._pokemon.baseStats.S
  }

  get h(): number {
    const st = this._status.H
    return this._calculator.calculateH(st.iv, st.ev)
  }

  get a(): number {
    const st = this._status.A
    return this._calculator.calculateA(st.iv, st.ev)
  }

  get b(): number {
    const st = this._status.B
    return this._calculator.calculateB(st.iv, st.ev)
  }

  get c(): number {
    const st = this._status.C
    return this._calculator.calculateC(st.iv, st.ev)
  }

  get d(): number {
    const st = this._status.D
    return this._calculator.calculateD(st.iv, st.ev)
  }

  get s(): number {
    const st = this._status.S
    return this._calculator.calculateS(st.iv, st.ev)
  }

  get evH(): number {
    return this._status.H.ev
  }

  get evA(): number {
    return this._status.A.ev
  }

  get evB(): number {
    return this._status.B.ev
  }

  get evC(): number {
    return this._status.C.ev
  }

  get evD(): number {
    return this._status.D.ev
  }

  get evS(): number {
    return this._status.S.ev
  }
}

export default defineComponent({
  components: {
    DropDown,
    StatusCard,
  },
  data(): DataType {
    return {
      pokemonList: pokemonList,
      selectedPokemon: null,
      selectedNature: pokemonNatures[6],
      level: 50,
      status: {
        H: {
          ev: 0,
          iv: 31,
        },
        A: {
          ev: 0,
          iv: 31,
        },
        B: {
          ev: 0,
          iv: 31,
        },
        C: {
          ev: 0,
          iv: 31,
        },
        D: {
          ev: 0,
          iv: 31,
        },
        S: {
          ev: 0,
          iv: 31,
        },
      },
      natureList: pokemonNatures,
      pinnedItems: [],
    }
  },
  methods: {
    onEffortValueChange: function (
      target: number,
      current: number,
      next: number
    ): number {
      const max = 508
      const after = this.evTotal + next - current
      if (after > max) {
        const over = after - max
        next -= over
      }

      const newStatus: StatusParameter = {
        ev: next,
        iv: this.status[target].iv,
      }
      this.status[target] = newStatus
      return next
    },
    getTableStyle: function (v: string): string {
      if (v === this.selectedNature.plus) {
        return 'background-red'
      }
      if (v === this.selectedNature.minus) {
        return 'background-blue'
      }
      return 'background-default'
    },
    pinItem: function (): void {
      if (this.currentPokemon === null) {
        return
      }

      this.pinnedItems.push(this.currentPokemon)
    },
    deleteItem: function (num: number): void {
      if (num > this.pinnedItems.length) {
        return
      }

      this.pinnedItems.splice(num, 1)
    },
    promoteItem: function (num: number): void {
      if (num > this.pinnedItems.length) {
        return
      }

      const target: DisplayPokemon = this.pinnedItems[num]
      this.selectedPokemon = target.pokemon
      nextTick(() => {
        this.selectedNature = target.nature
        this.status = cloneDeep(target.status)
        this.level = target.level
      })
    },
    resetValues: function (): void {
      this.level = 50
      this.selectedNature = pokemonNatures[6]
      this.status = {
        H: {
          ev: 0,
          iv: 31,
        },
        A: {
          ev: 0,
          iv: 31,
        },
        B: {
          ev: 0,
          iv: 31,
        },
        C: {
          ev: 0,
          iv: 31,
        },
        D: {
          ev: 0,
          iv: 31,
        },
        S: {
          ev: 0,
          iv: 31,
        },
      }
    },
    updateSelectedValue: function (val: Pokemon): void {
      this.selectedPokemon = val
      this.resetValues()
    },
    getCurrentPokemon(): DisplayPokemon | null {
      if (this.selectedPokemon === null) {
        return null
      }

      return new DisplayPokemon(
        this.selectedPokemon,
        cloneDeep(this.status),
        this.level,
        this.selectedNature
      )
    },
  },
  computed: {
    currentPokemon(): DisplayPokemon | null {
      if (this.selectedPokemon === null) {
        return null
      }

      return new DisplayPokemon(
        this.selectedPokemon,
        cloneDeep(this.status),
        this.level,
        this.selectedNature
      )
    },
    evTotal(): number {
      return (
        this.status.H.ev +
        this.status.A.ev +
        this.status.B.ev +
        this.status.C.ev +
        this.status.D.ev +
        this.status.S.ev
      )
    },
  },
  watch: {
    selectedPokemon: {
      handler: function () {
        this.resetValues()
      },
      deep: true,
    },
  },
})
</script>

<style scoped lang="scss">
@use '@/style/style';

label {
  display: inline;
}

.left-space {
  padding-left: 5%;
}

.range-slider {
  width: 90%;
}

.background-red {
  background-color: #fff0f0;
}

.background-blue {
  background-color: #edf6ff;
}

.background-default {
  background-color: transparent;
}

.pinned-item {
  transition: 0.3s;
  width: 80%;
  padding-top: 4%;
  padding-bottom: 4%;
  margin: auto;
  margin-top: 5%;
}

.pinned-item p {
  padding-top: 2%;
}

.saved-items {
  position: relative;
  background: #f3f1f1;
  padding: 2% 7%;
  color: #474747;
}

.saved-items:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f02e';
  display: inline-block;
  position: absolute;
  line-height: 250%;
  width: 8%;
  color: white;
  background: #2c8898;
  text-align: center;
  left: -1.35em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
}
</style>
