
import { defineComponent } from 'vue'
import HeaderItem from './components/HeaderItem.vue'

export default defineComponent({
  name: 'App',
  components: {
    HeaderItem,
  },
})
