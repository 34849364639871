<template>
  <header id="pokemon-tool">
    <div class="header-container">
      <div class="header-top">
        <router-link to="/">Pokémon Tool</router-link>
      </div>

      <div class="header-linkgroup">
        <div v-bind:class="getLinkStyle(['/', '/status'])">
          <router-link to="/status">ステータス計算</router-link>
        </div>
        <div v-bind:class="getLinkStyle(['/ivs'])">
          <router-link to="/ivs">個体値計算</router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    getLinkStyle(links: Array<string>): string {
      return links.includes(this.$route.path)
        ? 'header-link-current'
        : 'header-link'
    },
  },
})
</script>

<style scoped lang="scss">
#pokemon-tool {
  width: 100vw;
  background-color: #4a4a4a;
  margin-left: 50%;
  transform: translateX(-50%) translateY(-25%);
  height: 55px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(15%);
}

@mixin link-text($font-size) {
  color: #f9f9f9;
  font-weight: bold;
  font-size: $font-size;
}

.header-top {
  margin-left: 2vw;
  a {
    $font-size: clamp(20px, 3vw, 28px);
    @include link-text($font-size);
  }
}

.header-linkgroup {
  display: flex;
  justify-content: baseline;
}

@mixin header-link-base() {
  margin-right: 2vw;
  a {
    $font-size: clamp(11px, calc(7px + 0.5vw), 20px);
    @include link-text($font-size);
  }
}

.header-link {
  @include header-link-base();
}

.header-link-current {
  @include header-link-base();
  a {
    border-bottom: solid 2px #f9f9f9;
  }
}
</style>
