/** * used following code *
https://gist.github.com/kenzauros/06ebe8b1e57fc3759c1cf471cbd8c411 */

<template>
  <div ref="wrapper" class="fd__wrapper">
    <input
      ref="textBox"
      type="text"
      class="fd__display-textbox"
      placeholder="ポケモンを選んでください"
      readonly="readonly"
      @click="textBoxClicked"
    />
    <div class="fd__list" v-show="showList">
      <div class="fd__filter-input">
        <input
          ref="filterTextBox"
          type="text"
          class="fd__filter-textbox"
          placeholder="Filter..."
          v-model="filterString"
        />
      </div>
      <ul v-if="filteredPokemonList.length > 0">
        <li
          class="fd__item"
          v-for="item in filteredPokemonList"
          :key="item.fullName"
          :value="item.fullName"
          @click.stop.prevent="itemClicked(item)"
        >
          {{ item.fullName }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

type DataType = {
  filterString: string
  showList: boolean
}

type PokemonProperty = {
  fullName: string
}

export default defineComponent({
  data(): DataType {
    return {
      filterString: '',
      showList: false,
    }
  },
  props: {
    modelValue: { type: Object, default: null },
    pokemonList: {
      type: Array as PropType<PokemonProperty[]>,
      default: () => [],
    },
  },
  computed: {
    filteredPokemonList(): Array<PokemonProperty> {
      if (!this.filterString) {
        return this.pokemonList
      }

      const katakana = this.toKatakana(this.filterString)
      return this.pokemonList.filter(x => x.fullName.includes(katakana))
    },
  },
  methods: {
    // eslint-disable-next-line
    refs(): any {
      return this.$refs
    },
    textBoxClicked(): void {
      this.showList = !this.showList
    },
    itemClicked(item: PokemonProperty): void {
      if (item) {
        this.$emit('update:modelValue', item)
      } else {
        this.$emit('update:modelValue', null)
      }
      this.reset()
    },
    reset() {
      this.showList = false
      this.filterString = ''
    },
    toKatakana(str: string): string {
      // https://www.nishishi.com/javascript-tips/regexp-katakana-hiragana.html
      const result = str.replace(/[ぁ-ん]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60)
      })

      return result
    },
  },
  watch: {
    showList(val: boolean): void {
      this.$nextTick(() => {
        if (val) {
          this.refs().filterTextBox.focus()
        }
      })
    },
  },
  mounted() {
    document.addEventListener('click', (e: MouseEvent) => {
      if (!this.refs()?.wrapper?.contains(e.target)) {
        this.reset()
      }
    })
  },
})
</script>

<style lang="scss" scoped>
.fd__wrapper {
  position: relative;
  width: 100%;
  * {
    font-size: 1rem;
  }
  input.fd__display-textbox,
  input.fd__filter-textbox {
    width: 100%;
  }
  .fd__list {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 4px;
    border: solid 1px rgb(192, 192, 192);
    background: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    margin-top: -1px;
    ul {
      margin: 4px 0 0 0;
      padding: 4px;
      overflow-x: auto;
      overflow-y: scroll;
      max-height: 40vh;
      li {
        padding: 4px 1px;
        cursor: pointer;
        list-style: none;
        line-height: 1;
        &.fd__item:hover {
          background: aliceblue;
        }
      }
    }
  }
}
</style>
