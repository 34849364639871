import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import StatusCalculator from '@/views/StatusCalculator.vue'
import IndividualValueCalculator from '@/views/IndividualValueCalculator.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/status',
    name: 'StatusCalculator',
    component: StatusCalculator,
  },
  {
    path: '/ivs',
    name: 'IndividualValueCalculator',
    component: IndividualValueCalculator,
  },
  {
    path: '/',
    name: 'default',
    component: StatusCalculator,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
