<template>
  <div class="status-card">
    <div class="card-container">
      <span class="card-title">{{ fullName }}</span>
      <span class="button-list" v-if="isPinned === false">
        <button type="button" v-on:click="clickPin" class="pin-button">
          <i class="far fa-bookmark"></i>
        </button>
      </span>

      <span class="button-list" v-if="isPinned === true">
        <button type="button" v-on:click="clickPromote" class="promote-button">
          <i class="fas fa-exchange-alt"></i>
        </button>
        <button type="button" v-on:click="clickDelete" class="delete-button">
          <i class="fas fa-trash-alt"></i>
        </button>
      </span>
      <p class="card-sub-text">性格: {{ nature }} レベル: {{ level }}</p>
      <hr />
      <p class="card-text">
        種族値: {{ baseStats }}<br />
        努力値: {{ ev }} (合計: {{ evH + evA + evB + evC + evD + evS }})<br />
        実数値: {{ h }}-{{ a }}-{{ b }}-{{ c }}-{{ d }}-{{ s }}<br />
      </p>
      <p class="card-text">
        耐久指数 (物理): {{ eiB }}<br />
        耐久指数 (特殊): {{ eiD }}<br />
        耐久指数 (総合): {{ eiB + eiD }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    fullName: {
      type: String,
      default: '',
    },
    nature: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: null,
    },
    h: {
      type: Number,
      default: null,
    },
    a: {
      type: Number,
      default: null,
    },
    b: {
      type: Number,
      default: null,
    },
    c: {
      type: Number,
      default: null,
    },
    d: {
      type: Number,
      default: null,
    },
    s: {
      type: Number,
      default: null,
    },
    baseStatH: {
      type: Number,
      default: null,
    },
    baseStatA: {
      type: Number,
      default: null,
    },
    baseStatB: {
      type: Number,
      default: null,
    },
    baseStatC: {
      type: Number,
      default: null,
    },
    baseStatD: {
      type: Number,
      default: null,
    },
    baseStatS: {
      type: Number,
      default: null,
    },
    evH: {
      type: Number,
      default: 0,
    },
    evA: {
      type: Number,
      default: 0,
    },
    evB: {
      type: Number,
      default: 0,
    },
    evC: {
      type: Number,
      default: 0,
    },
    evD: {
      type: Number,
      default: 0,
    },
    evS: {
      type: Number,
      default: 0,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    baseStats(): string {
      return `${this.baseStatH}-${this.baseStatA}-${this.baseStatB}-${this.baseStatC}-${this.baseStatD}-${this.baseStatS}`
    },
    ev(): string {
      return `${this.evH}-${this.evA}-${this.evB}-${this.evC}-${this.evD}-${this.evS}`
    },
    eiB(): number {
      return this.h * this.b
    },
    eiD(): number {
      return this.h * this.d
    },
  },
  methods: {
    clickPin(): void {
      this.$emit('item-pinned')
    },
    clickDelete(): void {
      this.$emit('item-deleted')
    },
    clickPromote(): void {
      this.$emit('item-promoted')
    },
    showsPinButton(): boolean {
      return this.isPinned === false
    },
    showsDeleteButton(): boolean {
      return this.isPinned === true
    },
  },
})
</script>

<style lang="sass" scoped>
.status-card
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2)
  transition: 0.3s
  border-radius: 10px
  width: 85%
  margin: auto
  &:hover
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2)

.card-container
  margin-top: 5%
  padding: 3% 5%

.card-title
  font-weight: bold

.card-text
  font-size: 85%
  margin-bottom: 10px

.card-sub-text
  font-size: 80%
  margin-top: 10px
  margin-bottom: 10px
  color: #888

button
  border-radius: 10px
  margin-left: 5px
  &:active
    padding-top: 6px
    padding-bottom: 4px
    border: 1px solid #141a20
    color: #e0ebf5
    -webkit-box-shadow: inset 0px 0px 8px #141a20
    -moz-box-shadow: inset 0px 0px 2px #141a20
    box-shadow: inset 0px 0px 2px #141a20

.button-list
  float: right
  display: flex
  justify-content: flex-end
  -webkit-box-pack: end
  -ms-flex-pack: end

.pin-button
  float: right
  + *
    clear: both

.delete-button
  float: right
  + *
    clear: both
</style>
