
import { defineComponent, PropType } from 'vue'

type DataType = {
  filterString: string
  showList: boolean
}

type PokemonProperty = {
  fullName: string
}

export default defineComponent({
  data(): DataType {
    return {
      filterString: '',
      showList: false,
    }
  },
  props: {
    modelValue: { type: Object, default: null },
    pokemonList: {
      type: Array as PropType<PokemonProperty[]>,
      default: () => [],
    },
  },
  computed: {
    filteredPokemonList(): Array<PokemonProperty> {
      if (!this.filterString) {
        return this.pokemonList
      }

      const katakana = this.toKatakana(this.filterString)
      return this.pokemonList.filter(x => x.fullName.includes(katakana))
    },
  },
  methods: {
    // eslint-disable-next-line
    refs(): any {
      return this.$refs
    },
    textBoxClicked(): void {
      this.showList = !this.showList
    },
    itemClicked(item: PokemonProperty): void {
      if (item) {
        this.$emit('update:modelValue', item)
      } else {
        this.$emit('update:modelValue', null)
      }
      this.reset()
    },
    reset() {
      this.showList = false
      this.filterString = ''
    },
    toKatakana(str: string): string {
      // https://www.nishishi.com/javascript-tips/regexp-katakana-hiragana.html
      const result = str.replace(/[ぁ-ん]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) + 0x60)
      })

      return result
    },
  },
  watch: {
    showList(val: boolean): void {
      this.$nextTick(() => {
        if (val) {
          this.refs().filterTextBox.focus()
        }
      })
    },
  },
  mounted() {
    document.addEventListener('click', (e: MouseEvent) => {
      if (!this.refs()?.wrapper?.contains(e.target)) {
        this.reset()
      }
    })
  },
})
