<template>
  <div>
    <HeaderItem />
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import HeaderItem from './components/HeaderItem.vue'

export default defineComponent({
  name: 'App',
  components: {
    HeaderItem,
  },
})
</script>
